import PropTypes from 'prop-types';
// @mui
import { Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@mui/material/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import EmailIcon from '@mui/icons-material/Email';

import ErrorIcon from '@mui/icons-material/Error';
import EuroIcon from '@mui/icons-material/Euro';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PostAddIcon from '@mui/icons-material/PostAdd';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import dayjs from 'dayjs';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------
StatusTimeLineVertical.propTypes = {
  thgProcess: PropTypes.object,
};

export default function StatusTimeLineVertical({ thgProcess }) {
  const theme = useTheme();
  const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));

  const claimProcess = thgProcess.claims.find((claim) => claim.type === 'client');
  const thgStatus =
    thgProcess.status === 'readyForPayout' || thgProcess.status === 'waitingForUba' ? 'sold' : thgProcess.status;

  const rejectionType = thgProcess.rejectionType;

  const stepsValues = [
    'new',
    thgStatus === 'rejected' && rejectionType === 'validation' ? 'rejected' : 'validated',
    thgStatus === 'rejected' && rejectionType === 'transmission' ? 'rejected' : 'submitted',
    'sold',
    'paidOut',
    thgStatus === 'rejected' && rejectionType === 'uba' ? 'rejected' : 'completed',
    'rejected',
  ];
  const index = stepsValues.indexOf(thgStatus);
  return (
    <Timeline sx={{ marginBottom: '100px' }} position="left">
      <TimelineItem>
        <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
          {dayjs(thgProcess.timestamp_verifiedAt || thgProcess.timestamp_createdAt).format('DD.MM.YYYY HH:mm')}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: index >= 0 ? 'primary.main' : 'grey' }} />
          <TimelineDot color={index >= 0 ? 'primary' : 'grey'}>
            <PostAddIcon />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: index >= 0 ? 'primary.main' : 'grey' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
          <Typography component="span" sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}>
            Vermarktung gestartet
          </Typography>
        </TimelineContent>
      </TimelineItem>

      {thgStatus === 'rejected' && rejectionType === 'validation' ? (
        <TimelineItem>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
            {thgProcess.timestamp_rejectedAt && dayjs(thgProcess.timestamp_rejectedAt).format('DD.MM.YYYY HH:mm')}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
            <TimelineDot color={index >= 1 ? 'error' : 'grey'}>
              <ErrorIcon />
            </TimelineDot>
            <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
            <Typography component="span" sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}>
              Abgelehnt bei der Datenprüfung
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ) : (
        <TimelineItem>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
            {thgProcess.timestamp_validatedAt && dayjs(thgProcess.timestamp_validatedAt).format('DD.MM.YYYY HH:mm')}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: index >= 1 ? 'primary.main' : 'grey' }} />
            <TimelineDot color={index >= 1 ? 'primary' : 'grey'}>
              <PlaylistAddCheckIcon />
            </TimelineDot>
            <TimelineConnector sx={{ bgcolor: index >= 1 ? 'primary.main' : 'grey' }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
            <Typography component="span" sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}>
              Datenprüfung durchgeführt
            </Typography>
            {/* <Typography>Because it&apos;s awesome!</Typography> */}
          </TimelineContent>
        </TimelineItem>
      )}
      {thgStatus === 'rejected' && rejectionType === 'transmission' ? (
        <TimelineItem>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
            {thgProcess.timestamp_rejectedAt && dayjs(thgProcess.timestamp_rejectedAt).format('DD.MM.YYYY HH:mm')}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
            <TimelineDot color={index >= 2 ? 'error' : 'grey'}>
              <ErrorIcon />
            </TimelineDot>
            <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
            <Typography component="span" sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}>
              Vom Quotenhändler abgelehnt
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ) : (
        <TimelineItem>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
            {thgProcess.timestamp_submittedAt && dayjs(thgProcess.timestamp_submittedAt).format('DD.MM.YYYY HH:mm')}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: index >= 2 ? 'primary.main' : 'grey' }} />
            {/* <TimelineDot color="primary" variant="outlined"> */}
            <TimelineDot color={index >= 2 ? 'primary' : 'grey'}>
              <UploadFileIcon />
            </TimelineDot>
            <TimelineConnector sx={{ bgcolor: index >= 2 ? 'primary.main' : 'grey' }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
            <Typography component="span" sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}>
              Fahrzeugdaten eingereicht
            </Typography>
          </TimelineContent>
        </TimelineItem>
      )}

      <TimelineItem>
        <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
          {thgProcess.timestamp_soldAt && dayjs(thgProcess.timestamp_soldAt).format('DD.MM.YYYY HH:mm')}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: index >= 3 ? 'primary.main' : 'grey' }} />
          <TimelineDot color={index >= 3 ? 'primary' : 'grey'}>
            <QueryStatsIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
          <Typography component="span" sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}>
            {thgProcess.instantPayout ? 'THG-Quote vermarktet' : 'Beim UBA eingereicht'}
          </Typography>
        </TimelineContent>
      </TimelineItem>
      {/* <TimelineItem>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
            {thgProcess.timestamp_readyforPayoutAt &&
              dayjs(thgProcess.timestamp_readyforPayoutAt).format('DD.MM.YYYY HH:mm')}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: index >= 4 ? 'primary.main' : 'grey' }} />
            <TimelineDot color={index >= 4 ? 'primary' : 'grey'}>
              <CreditScoreIcon />
            </TimelineDot>
            <TimelineConnector sx={{ bgcolor: index >= 4 ? 'primary.main' : 'grey' }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
            <Typography  component="span" sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}>
              Bereit zur Auszahlung
            </Typography>
          </TimelineContent>
        </TimelineItem> */}
      {Boolean(thgProcess.instantPayout) && (
        <TimelineItem>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
            {thgProcess.timestamp_paidOutAt && dayjs(thgProcess.timestamp_paidOutAt).format('DD.MM.YYYY HH:mm')}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: index >= 4 ? 'primary.main' : 'grey' }} />
            <TimelineDot color={index >= 4 ? 'primary' : 'grey'}>
              <EuroIcon />
            </TimelineDot>
            {claimProcess && <TimelineConnector sx={{ bgcolor: index >= 4 ? 'primary.main' : 'grey' }} />}
          </TimelineSeparator>
          <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
            <Typography component="span" sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}>
              Auszahlung erfolgt
            </Typography>
          </TimelineContent>
        </TimelineItem>
      )}

      {thgStatus === 'rejected' && rejectionType === 'uba' ? (
        <>
          <TimelineItem>
            <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
              {thgProcess.timestamp_rejectedAt && dayjs(thgProcess.timestamp_rejectedAt).format('DD.MM.YYYY HH:mm')}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
              <TimelineDot color={index >= 4 ? 'error' : 'grey'}>
                <ErrorIcon />
              </TimelineDot>
              <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
              <Typography component="span" sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}>
                Ihr Antrag wurde vom Umweltbundesamt abgelehnt
              </Typography>
            </TimelineContent>
          </TimelineItem>
          {Boolean(claimProcess && !claimProcess.isManual) && (
            <>
              <TimelineItem>
                <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
                  {claimProcess.timestamp_requestedAt &&
                    dayjs(claimProcess.timestamp_requestedAt).format('DD.MM.YYYY HH:mm')}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector
                    sx={{
                      bgcolor: 'primary.main',
                    }}
                  />
                  <TimelineDot color={claimProcess.timestamp_requestedAt ? 'primary' : 'grey'}>
                    <EmailIcon />
                  </TimelineDot>
                  <TimelineConnector sx={{ bgcolor: 'grey' }} />
                </TimelineSeparator>

                <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
                  <Typography
                    component="span"
                    sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}
                  >
                    Rückzahlung angefordert
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
                  {claimProcess.timestamp_bankReceivedAt &&
                    dayjs(claimProcess.timestamp_bankReceivedAt).format('DD.MM.YYYY HH:mm')}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector
                    sx={{
                      bgcolor: claimProcess.timestamp_bankReceivedAt ? 'primary.main' : 'grey',
                    }}
                  />
                  <TimelineDot color={claimProcess.timestamp_bankReceivedAt ? 'primary' : 'grey'}>
                    <PriceCheckIcon sx={{ color: '#FFF' }} />
                  </TimelineDot>
                </TimelineSeparator>

                <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
                  <Typography
                    component="span"
                    sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}
                  >
                    Rückzahlung erhalten
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            </>
          )}
        </>
      ) : (
        <>
          {Boolean(claimProcess && claimProcess.isManual) && (
            <>
              <TimelineItem>
                <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
                  {claimProcess.created_at && dayjs(claimProcess.created_at).format('DD.MM.YYYY HH:mm')}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector
                    sx={{
                      bgcolor: 'primary.main',
                    }}
                  />
                  <TimelineDot color={'error'}>
                    <ErrorIcon />
                  </TimelineDot>
                  <TimelineConnector sx={{ bgcolor: 'grey' }} />
                </TimelineSeparator>

                <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
                  <Typography
                    component="span"
                    sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}
                  >
                    Vermarktung gestoppt
                    <br />
                    Rückzahlung angefordert
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
                  {claimProcess.timestamp_bankReceivedAt &&
                    dayjs(claimProcess.timestamp_bankReceivedAt).format('DD.MM.YYYY HH:mm')}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector
                    sx={{
                      bgcolor: claimProcess.timestamp_bankReceivedAt ? 'primary.main' : 'grey',
                    }}
                  />
                  <TimelineDot color={claimProcess.timestamp_bankReceivedAt ? 'primary' : 'grey'}>
                    <PriceCheckIcon sx={{ color: '#FFF' }} />
                  </TimelineDot>
                </TimelineSeparator>

                <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
                  <Typography
                    component="span"
                    sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}
                  >
                    Rückzahlung erhalten
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {Boolean(!claimProcess || (claimProcess && !claimProcess.isManual)) && (
            <>
              <TimelineItem>
                <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
                  {thgProcess.instantPayout
                    ? thgProcess.timestamp_completedAt &&
                      dayjs(thgProcess.timestamp_completedAt).format('DD.MM.YYYY HH:mm')
                    : thgProcess.timestamp_readyForPayoutAt &&
                      dayjs(thgProcess.timestamp_readyForPayoutAt).format('DD.MM.YYYY HH:mm')}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector
                    sx={{
                      bgcolor:
                        index >= 5 || (thgProcess.status === 'readyForPayout' && !thgProcess.instantPayout)
                          ? 'primary.main'
                          : 'grey',
                    }}
                  />
                  <TimelineDot
                    color={
                      index >= 5 || (thgProcess.status === 'readyForPayout' && !thgProcess.instantPayout)
                        ? 'primary'
                        : 'grey'
                    }
                  >
                    <CreditScoreIcon />
                  </TimelineDot>
                  {!thgProcess.instantPayout && (
                    <TimelineConnector sx={{ bgcolor: index >= 5 ? 'primary.main' : 'grey' }} />
                  )}
                </TimelineSeparator>

                <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
                  <Typography
                    component="span"
                    sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}
                  >
                    Vom UBA bestätigt
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              {Boolean(thgProcess.charityAmount && thgProcess.charityAmount > 0) && (
                <TimelineItem>
                  <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
                    {thgProcess.timestamp_completedAt &&
                      dayjs(thgProcess.timestamp_completedAt).format('DD.MM.YYYY HH:mm')}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector
                      sx={{
                        bgcolor:
                          index >= 5 || (thgProcess.status === 'readyForPayout' && !thgProcess.instantPayout)
                            ? 'primary.main'
                            : 'grey',
                      }}
                    />
                    <TimelineDot
                      color={
                        index >= 5 || (thgProcess.status === 'readyForPayout' && !thgProcess.instantPayout)
                          ? 'primary'
                          : 'grey'
                      }
                    >
                      <Iconify icon={'mdi:charity-outline'} sx={{ fontSize: '24px' }} />
                    </TimelineDot>
                    {!thgProcess.instantPayout && (
                      <TimelineConnector sx={{ bgcolor: index >= 5 ? 'primary.main' : 'grey' }} />
                    )}
                  </TimelineSeparator>

                  <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
                    <Typography
                      component="span"
                      sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}
                    >
                      Spende ausgezahlt
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              )}
            </>
          )}
        </>
      )}

      {Boolean(!thgProcess.instantPayout) && (
        <TimelineItem>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
            {thgProcess.timestamp_paidOutAt && dayjs(thgProcess.timestamp_paidOutAt).format('DD.MM.YYYY HH:mm')}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: index >= 4 ? 'primary.main' : 'grey' }} />
            <TimelineDot color={index >= 4 ? 'primary' : 'grey'}>
              <EuroIcon />
            </TimelineDot>
            {thgProcess.instantPayout && <TimelineConnector sx={{ bgcolor: index >= 4 ? 'primary.main' : 'grey' }} />}
          </TimelineSeparator>
          <TimelineContent sx={{ py: '20px', pr: 2, pl: 0 }}>
            <Typography component="span" sx={{ fontSize: isMobileView ? '0.8rem' : '1rem', verticalAlign: '-5px' }}>
              Auszahlung erfolgt
            </Typography>
          </TimelineContent>
        </TimelineItem>
      )}
    </Timeline>
  );
}
