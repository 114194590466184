import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  registerNewPartner: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeThemeColor, changeThemePartner } = useSettings();

  useEffect(() => {
    const initialize = async () => {
      try {
        const refreshToken = window.localStorage.getItem('refreshToken');

        if (refreshToken && isValidToken(refreshToken)) {
          const isStatusPage = window.location.pathname.startsWith('/status/');
          const isVerifyPage = window.location.pathname.startsWith('/verify/');
          const isPicsurePage = window.location.pathname.startsWith('/picsure/');
          const isThgqpPage = window.location.pathname.startsWith('/thgqp/');

          if (!isStatusPage && !isVerifyPage && !isPicsurePage && !isThgqpPage) {
            const response = await axios.post('/v1/auth/refresh-tokens', {
              refreshToken,
            });
            const { user, tokens } = response.data;

            changeThemeColor(user.partner.colorCode);
            changeThemePartner(user.partner.name);

            const newAccessToken = tokens.access.token;
            const newRefreshToken = tokens.refresh.token;

            // window.localStorage.removeItem('accessToken');
            // window.localStorage.removeItem('refreshToken');
            window.localStorage.setItem('accessToken', newAccessToken);
            window.localStorage.setItem('refreshToken', newRefreshToken);
            setSession(newAccessToken);

            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user,
              },
            });
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('refreshToken');
        window.location = '/';
        console.error('error:', err);
        setSession(null);
        dispatch({ type: 'LOGOUT' });
      }
    };

    //  TODO Remove sleep
    sleep(process.env.REACT_APP_ENVIRONMENT === 'local' ? 50 : 600).then(() => {
      initialize();
    });
  }, []);

  const login = async (email, password) => {
    const response = await axios.post('/v1/auth/login', {
      email,
      password,
    });

    const { tokens, user } = response.data;
    changeThemeColor(user.partner.colorCode);
    changeThemePartner(user.partner.name);

    window.localStorage.setItem('accessToken', tokens.access.token);
    window.localStorage.setItem('refreshToken', tokens.refresh.token);
    setSession(tokens.access.token);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const registerNewPartner = async (email, password, partnerId, firstName, lastName) => {
    const response = await axios.post('/v1/partner/register-new', {
      email,
      password,
      firstName,
      lastName,
      partnerId,
    });
    // const { accessToken, user } = response.data;

    // window.localStorage.setItem('accessToken', accessToken);
    // dispatch({
    //   type: 'REGISTER',
    //   payload: {
    //     user,
    //   },
    // });
  };

  const logout = async () => {
    const refreshToken = window.localStorage.getItem('refreshToken');
    if (refreshToken) {
      await axios.post('/v1/auth/logout', {
        refreshToken,
      });
    }
    //  no param for default theme
    changeThemeColor();
    changeThemePartner();

    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('accessToken');

    setSession(null);
    window.location = '/';

    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        registerNewPartner,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
