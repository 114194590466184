import { useEffect, useRef, useState } from 'react';
// @mui
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Container, Typography, Button, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import AuthCode from 'react-auth-code-input';

// components
import { useSnackbar } from 'notistack';

import axios from 'src/utils/axios';
import ThgqpFooter from 'src/components/thgqp/FooterStaticPages';
import GreenfactoryFooter from 'src/components/greenfactory/FooterStaticPages';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import LogoOnlyLayout from '../../../layouts/LogoOnlyLayout';
import TermsVerify from './terms';
import Summary from './summary';
import { PhoneNumberForm } from './PhoneVerification/form';
import { checkCode, sendCode } from './twilio';

dayjs.locale('de');

export default function ProcessVerify() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const { themeStretch } = useSettings();

  const { verifyToken = '' } = useParams();
  // const { partnerId = '' } = useParams();
  // const { platformPartner = '' } = useParams();

  const [phoneNumber, setPhoneNumber] = useState('');
  const authInputRef = useRef(null);
  const [data, setData] = useState(null);
  const [partnerId, setPartnerId] = useState(null);
  const [platformPartner, setPlatformPartner] = useState(null);
  const [thgs, setThgs] = useState([]);
  const [verified, setVerified] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifiedPhone, setVerifiedPhone] = useState(false);
  const [code, setCode] = useState('');
  const [authCodeView, setAuthCodeView] = useState(false);
  const [phoneVerification, setPhoneVerification] = useState(false);
  const [instantPayout, setInstantPayout] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const handleCodeChange = (c) => {
    setCode(c);
  };

  useEffect(() => {
    if (code.length === 6) {
      checkCode(phoneNumber, code).then((value) => {
        if (value === 'approved') {
          enqueueSnackbar('Telefonnummer wurde verifiziert', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
          setVerifiedPhone(true);
          verifyThg();
          setAuthCodeView(false);
        } else {
          // sendCode(phoneNumber);
          authInputRef.current?.clear();
          enqueueSnackbar('Verifizierungscode nicht korrekt', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
        }
      });
    }
  }, [code]);

  useEffect(() => {
    if (thgs && thgs.length > 0 && !loading) {
      const { directSubmit } = thgs[0];

      if (!directSubmit && !verified) {
        verifyThg();
      }
    }
  }, [thgs]);

  const { themeColors, onChangeColor, changeThemeColor, changeThemePartner } = useSettings();

  useEffect(() => {
    if (partnerId) {
      onChangeColor(partnerId.toLowerCase());
    }
    getThgsFromVerifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerId, verifyToken]);

  const getThgsFromVerifyToken = async () => {
    setLoading(true);
    try {
      const endpoint = `/v1/thg/verify/getThgs/${verifyToken}`;
      const response = await axios.get(endpoint);
      const partner = response?.data?.partner;
      if (partner) {
        onChangeColor(partner.name.toLowerCase(), partner.colorCode);
        changeThemeColor(partner.colorCode);
        changeThemePartner(partner.name);
        setPhoneVerification(partner.phoneVerification);
        setPlatformPartner(partner.platformPartner);
        setPartnerId(partner.name);
        setThgs(response.data.thgs);
        setInstantPayout(response.data.thgs[0].instantPayout);
      } else {
        setTokenExpired(true);
      }

      setLoading(false);
    } catch (err) {
      setError(true);
      setThgs(null);
      setLoading(false);
      console.error(err);
      // window.location = '/';
    }
  };

  const onSubmit = async () => {
    try {
      const hasErrors = Object.keys(errors).length !== 0;
      if (!hasErrors) {
        if (instantPayout && phoneVerification) {
          const response = await sendCode(phoneNumber);
          if (response.data.success) {
            setAuthCodeView(true);

            if (phoneNumber !== thgs[0].client.phone) {
              // update phone number if it was changed

              const endpoint = `/v1/thg/verify/updatePhone`;
              const data = { phoneNumber, verifyToken };
              const response = await axios.post(endpoint, data);
            }
          } else if (response.data.error) {
            const codeLabel = {
              60203: 'Verifizierungscode zu oft angefordert',
              21614: 'SMS Konnte nicht zugestellt werden',
              60205: 'Bitte geben Sie eine Mobilfunknummer an',
            };
            enqueueSnackbar(codeLabel[response.data.error.code], {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
          } else {
            enqueueSnackbar('Es ist ein Fehler aufgetreten.', {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
          }
        } else {
          verifyThg();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verifyThg = async () => {
    setLoading(true);
    try {
      if (!verified) {
        const endpoint = `/v1/thg/verify/${verifyToken}`;
        const response = await axios.get(endpoint);
        const partnerId = response?.data?.partnerId;
        if (partnerId) {
          // onChangeColor(partnerId.toLowerCase());
          // changeThemeColor(partner.colorCode);
          changeThemePartner(partnerId);
          setPartnerId(partnerId);
        }

        setVerified(true);
        setData(response.data);
        setLoading(false);
      }
    } catch (err) {
      setError(true);
      setVerified(false);
      setLoading(false);
      console.error(err);
      // window.location = '/';
    }
  };

  if (!platformPartner || !partnerId || !verifyToken || loading || !thgs || (thgs && thgs.length === 0)) {
    return (
      <Page
        title="E-Mail-Adresse verifizieren"
        sx={{
          '& .MuiFormControl-fullWidth': { marginBottom: 1 },
          display: 'flex',
          minHeight: '100vh',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Container
          sx={{
            padding: '32px',
            margin: '0px auto',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            width: '100%',
            maxWidth: { xs: '100%', md: '1400px' },
          }}
          maxWidth={themeStretch ? false : 'xl'}
        >
          <Box sx={{ minHeight: '75px' }}>
            <LogoOnlyLayout partnerId={partnerId} />
          </Box>
          <Box sx={{ textAlign: 'center', width: '100%', margin: 'auto', paddingTop: '20px' }}>
            {tokenExpired ? (
              <Box>
                <ErrorOutlineIcon color="error" fontSize="large" />
                <Typography variant="h5">
                  Dieser Aufruf ist nicht mehr gültig. Bitte reichen Sie Ihre Daten erneut ein.
                </Typography>
              </Box>
            ) : (
              <CircularProgress />
            )}
          </Box>{' '}
        </Container>
      </Page>
    );
  }

  //  Use first thg because all have the same property directsubmit
  const { directSubmit } = thgs[0];

  if (authCodeView) {
    return (
      <>
        <style>
          {`
          input {
            width: 50px;
            font-size: 3rem;
            height: 60px;
            text-align: center;
            margin: 10px;
            border-radius: 10px;
            border: 1px solid #ccc;
            outline: none;
            background-color: rgb(250,250,250);
          }
          
          @media (max-width: 768px) {
            input {
              width: 40px; 
              height: 45px; 
              font-size: 2rem;
            }
          }
  `}
        </style>
        <Box
          sx={{
            margin: 'auto',
            textAlign: 'center',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography>Bitte geben Sie den Verifizierungscode, den sie soeben per SMS erhalten haben, ein.</Typography>
          <AuthCode ref={authInputRef} value={code} allowedCharacters="numeric" onChange={handleCodeChange} />

          <Box sx={{ paddingTop: '20px', flexDirection: 'column', display: 'flex' }}>
            <Button
              sx={{ margin: '10px' }}
              onClick={() => {
                sendCode(phoneNumber).then((response) => {
                  if (response.data.success) {
                    enqueueSnackbar('Verifizierungscode erneut versendet', {
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                    });
                  } else if (response.data.error && response.data.error.code === 60203) {
                    enqueueSnackbar('Verifizierungscode zu oft angefordert', {
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                    });
                  } else {
                    enqueueSnackbar('Es ist ein Fehler aufgetreten', {
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                    });
                  }
                });
              }}
              variant="contained"
            >
              Verifizierungscode erneut anfordern{' '}
            </Button>
            <Button
              sx={{ margin: '10px' }}
              variant="outlined"
              onClick={() => {
                setAuthCodeView(false);
              }}
            >
              zurpck
            </Button>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Page
      title="E-Mail-Adresse verifizieren"
      sx={{
        '& .MuiFormControl-fullWidth': { marginBottom: 1 },
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      <Container
        sx={{
          padding: '32px',
          margin: '0px auto',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          width: '100%',
          maxWidth: { xs: '100%', md: '1400px' },
        }}
        maxWidth={themeStretch ? false : 'xl'}
      >
        <form style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }} onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ minHeight: '75px', margin: '0px -20px' }}>
            <LogoOnlyLayout partnerId={partnerId} />
          </Box>
          <Box sx={{ margin: '50px 0px' }}>
            {!verified && !error && directSubmit && (
              <Box>
                <Box sx={{ margin: 'auto', textAlign: 'center' }}>
                  <Typography>Nur noch ein Klick! Ihre E-Mail-Adresse wurde erfolgreich bestätigt.</Typography>
                  <Typography sx={{ marginBottom: '40px', marginTop: '10px', fontWeight: 700 }}>
                    Bitte überprüfen Sie die Daten und starten die Vermarktung Ihrer THG-Quote durch Klick auf den
                    Button.
                  </Typography>
                </Box>

                <Box>
                  {' '}
                  <Summary thgs={thgs} />
                  <TermsVerify control={control} errors={errors} platformPartner={platformPartner} />
                </Box>
                {phoneVerification && instantPayout && (
                  <PhoneNumberForm
                    data={thgs}
                    feedback={(value) => {
                      setPhoneNumber(value);
                    }}
                  />
                )}
                <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
                  <Button onClick={handleSubmit(onSubmit)} color="primary" size={'large'} variant="contained">
                    {instantPayout && phoneVerification
                      ? 'Telefonnummer verifizieren und Vermarktung starten!'
                      : 'Vermarktung jetzt starten!'}
                  </Button>
                </Box>
              </Box>
            )}

            {!loading && (error || !verifyToken) && directSubmit && (
              <Box sx={{ margin: 'auto', textAlign: 'center' }}>
                <CancelOutlinedIcon
                  color="error"
                  sx={{ fontSize: '45px', display: 'block', marginBottom: '40px', margin: 'auto' }}
                />
                <Typography>Die Vermarktung wurde bereits gestartet. Bitte prüfen Sie Ihren Posteingang.</Typography>
              </Box>
            )}
            {!loading &&
              !error &&
              verified &&
              data &&
              ((phoneVerification && verifiedPhone) || !instantPayout || !phoneVerification) && (
                <>
                  <Box sx={{ margin: 'auto', textAlign: 'center' }}>
                    <CheckCircleOutlineIcon
                      color="success"
                      sx={{ fontSize: '45px', display: 'block', margin: 'auto' }}
                    />

                    <Typography sx={{ marginTop: '10px', fontWeight: '700' }}>Herzlichen Glückwunsch!</Typography>
                    <Typography
                      sx={{ fontWeight: '700' }}
                    >{`Sie haben die Vermarktung Ihrer THG-Quote erfolgreich gestartet.`}</Typography>

                    <Typography sx={{ marginTop: '10px' }}>
                      Es wurde soeben eine Nachricht mit der Auftragsbestätigung an das Postfach{' '}
                      <strong>{data.client.email}</strong> gesendet, über die Sie sich jederzeit zum Status der
                      Vermarktung informieren können.
                      <br />
                      <strong>Hinweis:</strong> Sollten Sie keine Nachricht erhalten haben, überprüfen Sie bitte Ihren
                      SPAM-Ordner.
                    </Typography>
                  </Box>
                </>
              )}
          </Box>
        </form>
        {platformPartner === 'Greenfactory' ? (
          <GreenfactoryFooter noPadding={true} isPlatform={true} />
        ) : (
          <ThgqpFooter noPadding={true} isPlatform={true} />
        )}
      </Container>
    </Page>
  );
}
